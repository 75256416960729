
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ServiceBlock',
  props: {
    serviceName: String,
    copy: String,
    imgMain: Array,
    imgLogo: String,
    link: String
  }
})
