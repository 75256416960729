<template>
  <div class="service__item">
    <div class="image">
      <img class="image__img"
        :src="imgMain"
        :srcset="`${imgMain.size414w} 414w,
          ${imgMain.size828w} 828w,
          ${imgMain.size1242w} 1242w`"
        :alt="`${serviceName} main`">
    </div>
    <div class="name">
      <img class="name__img" :src="imgLogo" :alt="`${serviceName} logo`">
      <h1 class="name__copy" v-html="copy"></h1>
      <a class="name__btn" :href="link">{{ serviceName }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ServiceBlock',
  props: {
    serviceName: String,
    copy: String,
    imgMain: Array,
    imgLogo: String,
    link: String
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
