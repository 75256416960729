<template>
  <header>
    <img src="./assets/img/Logo-Brand-default.svg" alt="TastyTable logo" class="brandLogo">
  </header>
  <router-view/>
  <footer>
    <small class="copyright">© 2021 TastyTable</small>
  </footer>
</template>

<style lang="scss">

/* Configuration
-------------------- */
// break point
$breakpoints: (
  "max699": "(max-width: 699px)",
  "min700": "(min-width: 700px)"
) !default;
// color
$colors: (
  basic_black: #2C2A26,
  beige: #f3f0e8
);

/* Tools
-------------------- */
// color
@function colors($key) {
  @return map-get($colors, $key);
}
// Media Query @include mq(max699) {}
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

/* Reset, Base
-------------------- */
* {
  margin: 0;
  padding: 0;
}
body {
  font: 16px / 1.5 "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: colors(basic_black);
}
h1, p, a, small {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

/* Layout
-------------------- */
.brandLogo {
  width: auto;
  height: 18px;
}
.service {
  .service__item {
    font-size: 0;
    text-align: center;
  }
  .image {
    position: relative;
    z-index: 2;

    .image__img {
      width: 100%;
    }
  }
  .name {
    .name__img {
      width: 128px;
    }
    .name__copy {
      margin: 1rem 0;
      padding: 0 1em;
      font: 200 1rem / 1.5 "游明朝体", YuMincho, "游明朝", "Yu Mincho", serif;
    }
    .name__btn {
      display: inline-block;
      margin: 1em 0 0;
      padding: 1em 1.5em;
      background: colors(basic_black);
      border-radius: 5px;
      font: .875rem 'Montserrat', sans-serif;
      letter-spacing: .05em;
      text-decoration: none;
      color: colors(beige);
    }
  }
}
footer {
  font: .875rem 'Montserrat', sans-serif;
}

@include mq(max699) {
  header {
    margin: 0 0 -16px;
    padding: 24px 16px 0;
  }
  .service {
    .service__item {
      &:nth-child(odd) {
        .name {
          background: colors(beige);
        }
      }
    }
    .image {
      .image__img {
        mask-image: url('./assets/img/clip.svg');
        mask-size: cover;
      }
    }
    .name {
      position: relative;
      z-index: 1;
      margin: -18vw 0;
      padding: 120px 0;
    }
  }
  footer {
    padding: 12vw 16px 24px;
    background: colors(beige);
    text-align: right;
    mask-image: url('./assets/img/clip.svg');
    mask-size: cover;
  }
}
@include mq(min700) {
  #app {
    max-width: 828px;
    margin: 0 auto;
  }
  header {
    padding: 32px 0;
    text-align: center;
  }
  .service {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .image {
      width: 414px;

      .image__img {
        max-width: 414px;
      }
    }
    .name {
      margin: 32px 0 0;

      .name__btn {
        transition: all .3s;

        &:hover {
          background: #756B56;
        }
      }
    }
  }
  footer {
    margin: 48px 0 0;
    text-align: center;
  }
}
</style>