<template>
  <div class="service">
    <ServiceBlock
      v-for="item in items" :key="item.serviceClass"
      :serviceName="item.serviceName"
      :copy="item.copy"
      :imgMain="item.imgMain"
      :imgLogo="item.imgLogo"
      :link="item.link"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ServiceBlock from "@/components/ServiceBlock.vue";

export default defineComponent({
  name: 'Home',
  components: {
    ServiceBlock
  },
  data(){
    return {
      items: [
        {
          serviceName: 'TastyTable Mealkit',
          copy: '週末のおうちを“レストランに変える”<br>食材キット宅配サービス',
          imgMain: {
            size414w: require('@/assets/img/ttm-main-414.jpg'),
            size828w: require('@/assets/img/ttm-main-828.jpg'),
            size1242w: require('@/assets/img/ttm-main-1242.jpg')
          },
          imgLogo: require('@/assets/img/Logo-Mealkit-default.svg'),
          link: 'https://tastytable-mealkit.com/'
        },
        {
          serviceName: 'TastyTable Food',
          copy: '“手作りにこだわった”<br>レストランフードブランド',
          imgMain: {
            size414w: require('@/assets/img/ttf-main-414.jpg'),
            size828w: require('@/assets/img/ttf-main-828.jpg'),
            size1242w: require('@/assets/img/ttf-main-1242.jpg')
          },
          imgLogo: require('@/assets/img/Logo-Food-default.svg'),
          link: 'https://tastytable-food.com/pastasauce/'
        }
      ]
    }
  }
});
</script>